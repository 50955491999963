<template>
  <b-card>
    <app-collapse>
      <app-collapse-item title="Administrativo">
        <h6>Email: administrativo@visinonni.com.br</h6>
        <h6>Senha: visinonni*2021</h6>
        <a href="https://webmail.umbler.com/?email=administrativo@visinonni.com.br&focuspassword=true" target="_blank"> Abrir Webmail Administrativo </a>
      </app-collapse-item>
      <app-collapse-item title="SAC">
        <h6>Email: sac@visinonni.com.br</h6>
        <h6>Senha: visinonni*2021</h6>
        <a href="https://webmail.umbler.com/?email=sac@visinonni.com.br&focuspassword=true" target="_blank"> Abrir Webmail SAC </a>
      </app-collapse-item>
      <app-collapse-item title="Contato">
        <h6>Email: contato@visinonni.com.br</h6>
        <h6>Senha: visinonni*2021</h6>
        <a href="https://webmail.umbler.com/?email=contato@visinonni.com.br&focuspassword=true" target="_blank"> Abrir Webmail Contato </a>
      </app-collapse-item>
    </app-collapse>
  </b-card>
</template> 
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
}
</script>